export default defineNuxtRouteMiddleware((to, from) => {
  // const code = to.query.code as string
  // if (code && to.path.includes('callback')) {
  //   return {
  //     path: '/editor',
  //     query: {
  //       code
  //     }
  //   }
  // }
})
